import request from 'lib/request';
import config from 'config/app';
import lib from 'lib/commons';
import lang from 'lng/index';
const lng = lang[config.lng];

export const CURRENT_USER_REQUEST = 'CURRENT_USER_REQUEST';
export const CURRENT_USER_REQUEST_ERROR = 'CURRENT_USER_REQUEST_ERROR';
export const CURRENT_USER_REQUEST_SUCCESS = 'CURRENT_USER_REQUEST_SUCCESS';
export const CURRENT_USER_FETCHING = 'CURRENT_USER_FETCHING';

export const SHARE = 'SHARE';
export const SHARE_ERROR = 'SHARE_ERROR';
export const SHARE_SUCCESS = 'SHARE_SUCCESS';
export const IS_SHARING = 'IS_SHARING';

export const SPIN = 'SPIN';
export const SPIN_ERROR = 'SPIN_ERROR';
export const SPIN_SUCCESS = 'SPIN_SUCCESS';
export const IS_SPINNING = 'IS_SPINNING';

export const CLAIM_ERROR = 'CLAIM_ERROR';
export const CLAIM_SUCCESS = 'CLAIM_SUCCESS';
export const IS_CLAIMING = 'IS_CLAIMING';

export const CHECK_MATCH_ERROR = 'CHECK_MATCH_ERROR';
export const CHECK_MATCH_SUCCESS = 'CHECK_MATCH_SUCCESS';
export const IS_CHECKING_MATCH = 'IS_CHECKING_MATCH';

export const GET_HISTORY_ERROR = 'GET_HISTORY_ERROR';
export const GET_HISTORY_SUCCESS = 'GET_HISTORY_SUCCESS';
export const IS_GETTING_HISTORY = 'IS_GETTING_HISTORY';

export const REFRESH = 'REFRESH';
export const REFRESH_ERROR = 'REFRESH_ERROR';
export const REFRESH_SUCCESS = 'REFRESH_SUCCESS';
export const IS_REFRESHING = 'IS_REFRESHING';

export const UPDATE_JACKPOT = 'UPDATE_JACKPOT';
export const UPDATE_WINNER_SEMI_JACKPOT = 'UPDATE_WINNER_SEMI_JACKPOT';
export const UPDATE_WINNER_JACKPOT = 'UPDATE_WINNER_JACKPOT';

// export const CLAIMING_CLUB = 'CLAIMING_CLUB';
// export const CLAIM_CLUB_SUCCESS = 'CLAIM_CLUB_SUCCESS';
// export const CLAIM_CLUB_ERROR = 'CLAIM_CLUB_ERROR';

export const isCurrentUserFetching = () => {
	return {
		type: CURRENT_USER_FETCHING,
	};
};

export const getCurrentUser = () => {
	return (dispatch, getState) => {
		dispatch(isCurrentUserFetching());
		request('api/user/get').then(function (response) {
			if (response.status == 'successful') {
				dispatch(getCurrentUserSuccess(response));
			} else {
				if (response.error_code == 'no_account') {
					lib.showDownlad();
				}
				dispatch(getCurrentUserError(response));
			}
		});
	};
};

export const getCurrentUserSuccess = (response) => {
	return {
		type: CURRENT_USER_REQUEST_SUCCESS,
		payload: response.payload,
	};
};

export const getCurrentUserError = (response) => {
	return {
		type: CURRENT_USER_REQUEST_ERROR,
		payload: response.payload,
	};
};

//CHECk MATCH
export const isCheckingMatch = (response) => {
	return {
		type: IS_CHECKING_MATCH,
	};
};
export const checkMatch = (callback = () => {}) => {
	return (dispatch, getState) => {
		dispatch(isCheckingMatch());
		request('api/user/update-daily', 'POST', {
			body: JSON.stringify({}),
		}).then(function (response) {
			if (response.status == 'successful') {
				callback(response?.payload?.play_normal);
				dispatch(checkMatchSuccess(response));
				// const missionInfos = response?.payload?.play_normal;
				// if (missionInfos > 0) {
				//   lib.showMessage('Bạn đã cập nhật nhiệm vụ thành công!')
				// } else {
				//   lib.showMessage('Bạn chưa hoàn thành nhiệm vụ!')
				// }
			} else {
				lib.showError(response.error_code);
				dispatch(checkMatchError(response));
			}
		});
	};
};
export const checkMatchSuccess = (response) => {
	return {
		type: CHECK_MATCH_SUCCESS,
		payload: response.payload,
	};
};
export const checkMatchError = (response) => {
	return {
		type: CHECK_MATCH_ERROR,
		payload: response,
	};
};

//Spin
export const isSpinning = () => {
	return {
		type: IS_SPINNING,
	};
};
export const spin = (
	spin_type,
	payment_type,
	isDisableAnimation,
	callback = () => {}
) => {
	return (dispatch, getState) => {
		dispatch(isSpinning());
		request('api/user/spin', 'POST', {
			body: JSON.stringify({
				spin_type: parseInt(spin_type),
				payment_type: parseInt(payment_type),
			}),
		}).then(function (response) {
			if (response.status == 'successful') {
				callback(response.payload);
				if (isDisableAnimation) {
					setTimeout(() => {
						dispatch(spinSuccess(response));
					}, 1600);
				} else {
					setTimeout(() => {
						dispatch(spinSuccess(response));
					}, 2600);
				}
			} else {
				lib.showError(response.error_code);
				dispatch(spinError(response));
			}
		});
	};
};
export const spinSuccess = (response) => {
	return {
		type: SPIN_SUCCESS,
		payload: response.payload,
	};
};
export const spinError = (response) => {
	return {
		type: SPIN_ERROR,
		payload: response.payload,
	};
};

// CLAIM
export const isClaiming = () => {
	return {
		type: IS_CLAIMING,
	};
};

export const doClaim = (stage, rewardId, callback = () => {}) => {
	return (dispatch, getState) => {
		dispatch(isClaiming());
		request('api/user/claim', 'POST', {
			body: JSON.stringify({
				stage: parseInt(stage),
				spin_accumulation_reward_id: parseInt(rewardId),
			}),
		}).then(function (response) {
			if (response.status == 'successful') {
				dispatch(claimSuccess(response));
				callback(response.payload);
			} else {
				dispatch(claimError(response));
				lib.showError(response.error_code);
			}
		});
	};
};

export const claimSuccess = (response) => {
	return {
		type: CLAIM_SUCCESS,
		payload: response.payload,
	};
};

export const claimError = (response) => {
	return {
		type: CLAIM_ERROR,
		payload: response.payload,
	};
};

//get history
export const isGettingHistory = () => {
	return {
		type: IS_GETTING_HISTORY,
	};
};

export const getHistory = () => {
	return (dispatch, getState) => {
		dispatch(isGettingHistory());
		request('api/user/history').then(function (response) {
			if (response.status == 'successful') {
				dispatch(getHistorySuccess(response));
			} else {
				dispatch(getHistoryError(response));
				lib.showError(response.error_code);
			}
		});
	};
};

export const getHistorySuccess = (response) => {
	return {
		type: GET_HISTORY_SUCCESS,
		payload: response.payload,
	};
};

export const getHistoryError = (response) => {
	return {
		type: GET_HISTORY_ERROR,
		payload: response.payload,
	};
};
//Share
export const isSharing = (response) => {
	return {
		type: IS_SHARING,
	};
};
export const share = (shareCustom = null) => {
	return (dispatch, getState) => {
		dispatch(isSharing());
		request('api/user/share', 'POST', {
			body: JSON.stringify({}),
		}).then(function (response) {
			if (response.status == 'successful') {
				if (shareCustom) {
					shareCustom();
				} else {
					lib.showMessage(
						'<p class="text-center">Bạn đã chia sẻ thành công và nhận được 1 lượt chơi miễn phí! <br/>Lưu ý: Ưu đãi này chỉ nhận được một lần duy nhất trong suốt sự kiện</p>'
					);
				}
				dispatch(shareSuccess(response));
			} else {
				lib.showError(response.error_code);
				dispatch(shareError(response));
			}
		});
	};
};
export const shareSuccess = (response) => {
	return {
		type: SHARE_SUCCESS,
		payload: response.payload,
	};
};
export const shareError = (response) => {
	return {
		type: SHARE_ERROR,
		payload: response.payload,
	};
};

//Refresh FC
export const isRefreshing = () => {
	return {
		type: IS_REFRESHING,
	};
};
export const refresh = () => {
	return (dispatch, getState) => {
		dispatch(isRefreshing());
		request('api/user/update-balance', 'POST', {
			body: JSON.stringify({}),
		}).then(function (response) {
			if (response.status == 'successful') {
				lib.showMessage('Cập nhật FC, MC thành công').then((res) => {
					dispatch(refreshSuccess(response));
				});
			} else {
				lib.showError(response.error_code);
				dispatch(refreshError(response));
			}
		});
	};
};
export const refreshSuccess = (response) => {
	return {
		type: REFRESH_SUCCESS,
		payload: response.payload,
	};
};
export const refreshError = (response) => {
	return {
		type: REFRESH_ERROR,
		payload: response.payload,
	};
};
//CLAIM CLUB
// export const isClaimingClub = () => {
// 	return {
// 		type: CLAIMING_CLUB,
// 	};
// };
// export const claimClub = () => {
// 	return (dispatch, getState) => {
// 		dispatch(isClaimingClub());
// 		request('api/user/claim-club-spins', 'POST', {
// 			body: JSON.stringify({}),
// 		}).then(function (response) {
// 			if (response.status == 'successful') {
// 				lib.showMessage('Cập nhật ưu đãi thành công').then((res) => {
// 					dispatch(claimClubSuccess(response));
// 				});
// 			} else {
// 				lib.showError(response.error_code);
// 				dispatch(claimClubError(response));
// 			}
// 		});
// 	};
// };
// export const claimClubSuccess = (response) => {
// 	return {
// 		type: CLAIM_CLUB_SUCCESS,
// 		payload: response.payload,
// 	};
// };
// export const claimClubError = (response) => {
// 	return {
// 		type: CLAIM_CLUB_ERROR,
// 		payload: response.payload,
// 	};
// };
export const updateJackpot = (response) => {
	return (dispatch, getState) => {
		dispatch(updateJackpotSuccess(response));
	};
};
export const updateJackpotSuccess = (response) => {
	return {
		type: UPDATE_JACKPOT,
		payload: response,
	};
};
export const updateWinnerJackpot = (response) => {
	return (dispatch, getState) => {
		dispatch(updateWinnerJackpotSuccess(response));
	};
};
export const updateWinnerJackpotSuccess = (response) => {
	return {
		type: UPDATE_WINNER_JACKPOT,
		payload: response,
	};
};
export const updateWinnerSemiJackpot = (response) => {
	return (dispatch, getState) => {
		dispatch(updateWinnerSemiJackpotSuccess(response));
	};
};
export const updateWinnerSemiJackpotSuccess = (response) => {
	return {
		type: UPDATE_WINNER_SEMI_JACKPOT,
		payload: response,
	};
};
